@import "@/assets/style/_index.scss";

















































.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: $gray5;
  text-align: center;
}
